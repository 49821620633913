import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Bio from "../components/Bio"
import Section from "../components/Section"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { rhythm, scale } from "../theme/typography"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    console.log(this.props.data)

    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    let anchor = this.props.location.pathname
      .replace(/\/blog\//g, "")
      .replace(/\//g, "")

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <Section>
          <article>
            <header>
              <Link to={"/blog/#" + anchor}>
                ↰&nbsp;&nbsp;&nbsp;&nbsp;zurück zum Blog
              </Link>
              <h1
                style={{
                  marginTop: rhythm(1),
                  marginBottom: 0,
                }}
              >
                {post.frontmatter.h1}
              </h1>
              <p
                style={{
                  ...scale(-1 / 5),
                  display: `block`,
                  marginBottom: rhythm(1),
                }}
              >
                {post.frontmatter.date}
              </p>
            </header>
            <Img
              fluid={post.frontmatter.blog_image.childImageSharp.fluid}
              style={{
                marginBottom: rhythm(1),
              }}
            />
            <section dangerouslySetInnerHTML={{ __html: post.html }} />
            <hr
              style={{
                marginBottom: rhythm(1),
              }}
            />
            <footer>
              <Bio />
            </footer>
          </article>

          <nav>
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
                marginBottom: 0,
              }}
            >
              <li>
                <Link
                  // replace all spaces with "-"
                  to={"/blog/#" + anchor}
                >
                  ↰&nbsp;&nbsp;&nbsp;&nbsp;zurück zum Blog
                </Link>
              </li>
            </ul>
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.h1}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.h1} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </Section>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "D.MM.YYYY")
        description
        h1
        blog_image {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
